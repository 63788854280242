<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      height="100vh"
      permanent
      color="white darken-4"
      v-if="$store.state.token"
    >
      <v-divider color="blue-red"></v-divider>

      <v-list dense>
        <span v-for="item in items" :key="item.title">
          <v-list-group
            v-if="item.sub_items.length > 0"
            v-model="item.active"
            :prepend-icon="item.icon"
            link
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item
              class="SelectedTile"
              active-class="SelectedTile-active"
              v-for="child in item.sub_items"
              :key="child.title"
              :to="child.path"
              link
            >
              <v-list-item-content color="blue-grey lighten-5">
                <v-list-item-title class="ListItemClass">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            class="SelectedTile"
            active-class="SelectedTile-active"
            :key="item.title"
            :to="item.path"
            link
          >
            <v-list-item-icon color="blue-grey lighten-5">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content color="blue-grey lighten-5">
              <v-list-item-title class="ListItemClass">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark v-if="$store.state.token">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <img class="mr-3" :src="require('./assets/logo.png')" height="35" />
      <v-toolbar-title
        @click="$router.push('/').catch(() => {})"
        style="cursor: pointer"
        >TAMKEEN</v-toolbar-title
      >
      <!-- to put avatar in right side on the toolbar-->
      <v-spacer></v-spacer>

      <!------------------- #######################################################----------------------->

      <template>
        <v-btn icon x-large>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-avatar>
                  <img :src="user.photo" alt="" width="100" />
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <!-- <img :src="user.photo" alt="User Photo" /> -->
                      <v-icon x-large color="primary">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $store.state.userCredentials?.userName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $store.state.userCredentials?.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="editProfile">
                    <v-list-item-icon>
                      <v-icon>mdi-account-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Edit Profile</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="changeMyPassword">
                    <v-list-item-icon>
                      <v-icon>mdi-lock-reset</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="logout">
                    <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-card>
          </v-menu>
        </v-btn>
      </template>

      <!--------------------- #####################################################  ----------------- -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <!--  Edit User profile Dialog -->
    <!--  ------------------------- -->
    <v-dialog v-model="dialogEditProfile" width="50%">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Edit Profile</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon x-large>mdi-account</v-icon>
        </v-btn>
      </v-toolbar>

      <template>
        <v-container v-if="$store.state.token">
          <v-banner one-line style="color: black; background-color: white">
            <!-- <v-avatar style="color: white; height: 100px; font-size: small"> -->
            <v-card>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <div style="height: 20px"></div>
                  <v-text-field
                    label="User Name"
                    v-model="$store.state.userCredentials.userName"
                    outlined
                    dense
                  ></v-text-field>

                  <v-text-field
                    label="Email Address"
                    v-model="$store.state.userCredentials.email"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    label="Mobile Number"
                    v-model="$store.state.userCredentials.phoneNumber"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  class="d-flex align-center justify-center"
                >
                  <v-avatar size="200">
                    <img :src="user.photo" />
                  </v-avatar>
                  <!-- <img :src="user.photo" alt="" width="100" /> -->
                </v-col>

                <!-- <v-icon icon="mdi-lock" color="white"> mdi-image-area </v-icon> -->
                <!-- </v-avatar> -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <template>
                    <!-- <v-btn text color="deep-purple accent-4"> Add image </v-btn> -->
                    <v-btn text color="red" @click="saveUser"> Save </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="dialogEditProfile = false"
                    >
                      Cancel
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-banner>
        </v-container>
      </template>
    </v-dialog>

    <!--  Change User Password Dialog -->
    <!--  ------------------------- -->
    <v-dialog v-model="dialogChangePassword" width="50%">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Change Password</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon x-large>mdi-lock-reset</v-icon>
        </v-btn>
      </v-toolbar>

      <template>
        <v-container>
          <v-banner one-line style="color: black; background-color: white">
            <!-- <v-avatar style="color: white; height: 100px; font-size: small"> -->
            <v-card>
              <v-text-field
                v-model="currentPassword"
                label="Current Password"
                type="password"
                :rules="currentPasswordRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                label="New Password"
                type="password"
                :rules="newPasswordRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                label="Confirm New Password"
                type="password"
                :rules="confirmPasswordRules"
                required
              ></v-text-field>
              <v-btn
                text
                type="submit"
                color="red"
                @click="confirmChangePassword"
                >Change Password</v-btn
              >
              <v-btn text color="primary" @click="dialogChangePassword = false">
                CAncel
              </v-btn>
            </v-card>
          </v-banner>
        </v-container>
      </template>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    let user = localStorage.getItem("tamkeenLogin");
    if (user) {
      let token = localStorage.getItem("token");
      this.$store.state.token = token;
      this.$store.state.userCredentials = JSON.parse(user);
      this.$router.push("/").catch((e) => {});
    } else {
      this.$router.push("/login").catch((e) => {});
    }
  },

  data() {
    return {
      cards: ["Today", "Yesterday"],
      drawer: null,
      dialogEditProfile: false,
      dialogChangePassword: false,
      inputUsertName: "",

      inputEmailAddress: "",
      inputMobileNumber: "",

      currentPassword: "",
      newPassword: "",
      confirmPassword: "",

      currentPasswordRules: [
        (value) => !!value || "Current Password is required",
      ],
      newPasswordRules: [
        (value) =>
          (!!value && value.length >= 8) ||
          "New Password is required and must be at least 8 characters",
      ],
      confirmPasswordRules: [
        (value) =>
          (!!value && value == this.newPassword) ||
          "Confirm New Password is required and must match the New Password",
      ],
      user: {
        // name: "User-1",
        // email: "User-1@example.com",
        photo: require("@/assets/images/user-icon.png"),
      },

      items: [
        {
          icon: "mdi-monitor-dashboard",
          sub_items: [],
          title: "Dashboard",
          path: "/",
        },

        {
          icon: "mdi-wallet-membership",
          sub_items: [
            // { title: "All Members", path: "/Allmembers" },
            // { title: "Pending Members", path: "/Pendingmembers" },
            // { title: "Approved Members", path: "/Approvedmembers" },
            // { title: "Rejected Members", path: "/Rejectedmembers" },
          ],
          title: "Members",
          path: "/Allmembers", // No patha for menu
        },
        {
          icon: "mdi-account-group",
          sub_items: [],
          title: "Customers",
          path: "/Customers", // No path for menu
        },
        {
          icon: "mdi-panorama-variant-outline",
          sub_items: [],
          title: "Banners",
          path: "/Banners", // No path for menu
        },
        {
          icon: "mdi-post",
          sub_items: [],
          title: "Posts",
          path: "/Posts",
        },
        {
          icon: "mdi-package-variant",
          sub_items: [],
          title: "Products",
          path: "/Products",
        },
        {
          icon: "mdi-book-check-outline",
          sub_items: [],
          title: "Review Posts",
          path: "/reviewsPosts", // No patha for menu
        },
        {
          icon: "mdi-book-check-outline",
          sub_items: [],
          title: "Review Products",
          path: "/reviewsProducts", // No patha for menu
        },

        {
          icon: "mdi-bell-cog",
          sub_items: [],
          title: "All Push Notification",
          path: "/AllPushNotification", // No patha for menu
        },

        {
          icon: "mdi-bell-ring",
          sub_items: [],
          title: "Urgent Notification",
          path: "/UrgentNotification", // No patha for menu
        },

        {
          icon: "mdi-palette-swatch",
          sub_items: [
            { title: "Categories", path: "/Categories" },

            { title: "Currencies", path: "/Currencies" },
            { title: "Cities", path: "/Cities" },
          ],
          title: "Masters",
          path: "", // No patha for menu
        },
        {
          icon: "mdi-newspaper-variant-outline",
          sub_items: [],
          title: "News",
          path: "/News", // No patha for menu
        },
        {
          icon: "mdi-chat",
          sub_items: [],
          title: "Custmer care",
          path: "/Chat", // No patha for menu
        },
      ],
    };
  },

  methods: {
    editProfile() {
      this.dialogEditProfile = true;
    },
    saveUser() {
      this.$http
        .put(
          `${this.$store.state.apiUrl}/userInformation/${this.$store.state.userCredentials.idUser}`,
          {
            userName: this.$store.state.userCredentials.userName,
            email: this.$store.state.userCredentials.email,
            phoneNumber: this.$store.state.userCredentials.phoneNumber,
          },
          {
            headers: { Authorization: `Berar ${this.$store.state.token}` },
          }
        )
        .then((res1) => {
          console.log(res1.data);
          this.$toasted.success("Data has been seaved successfully");
          this.dialogEditProfile = false;
        });
    },
    confirmChangePassword() {
      if (
        this.newPassword &&
        this.confirmPassword &&
        this.newPassword === this.confirmPassword
      ) {
        this.$http
          .put(
            `${this.$store.state.apiUrl}/resetPassword/${this.$store.state.userCredentials.idUser}`,
            {
              newPassword: this.newPassword,
              oldPassword: this.currentPassword,
            },
            {
              headers: { Authorization: `Berar ${this.$store.state.token}` },
            }
          )
          .then((res1) => {
            console.log(res1.data);
            this.$toasted.success("Data has been seaved successfully");
            this.dialogChangePassword = false;
          })
          .catch((err) => {
            this.$toasted.error("Current password is incorrect");
          });
      }
    },
    changeMyPassword() {
      this.dialogChangePassword = true;
    },
    logout() {
      localStorage.removeItem("tamkeenLogin");
      localStorage.removeItem("token");
      location.reload();
    },
  },
};
</script>

<style>
.v-navigation-drawer--fixed {
  top: 65px !important;
}

.ListItemClass {
  color: #1a135c;
}

.SelectedTile:hover {
  border-radius: 4px;
  background: rgba(21, 76, 121, 0.19);
}

.SelectedTile-active {
  border-radius: 4px;
  background: rgba(21, 76, 121, 0.19);
}

.v-list-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
</style>
