<template>
  <div>
    <v-card style="margin: 30px 0px" order-radius="0.25rem">
      <v-row style="padding: 20px">
        <v-col cols="3">
          <v-icon
            size="70"
            :style="{ background: dash.color }"
            style="margin-top: -80px; border-radius: 5px"
            class="pa-4 elevation-3 mb-5"
            color="accent"
            >{{ dash.myicon }}</v-icon
          >
        </v-col>
        <v-col cols="9s">
          <h4
            class="warning-color"
            style="
              text-align: center;
              font-size: 45px;
              font-weight: lighter;
              color: #a0a2ac;
            "
          >
            {{ val }}
          </h4>
        </v-col>
      </v-row>
      <v-divider style="color: blueviolet"></v-divider>

      <h3
        style="
          color: #a0a2ac;
          text-align: center;
          font-size: 25px;
          font-weight: lighter;
        "
      >
        {{ dash.subtitle }}
      </h3>
    </v-card>
  </div>
</template>

<script>
export default {
  props: { dash: Object, val: Number },
};
</script>

<style lang="scss" scoped></style>
