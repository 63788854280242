<template>
  <v-card style="width: 100%; padding: 40px">
    <!-- <Bar id="Home-chart" :options="chartOptions" :data="chartData" /> -->
    <bar
      id="Home-chart"
      :options="chartOptions"
      :data="chartData"
      style="display: block; height: 550px"
    ></bar>
  </v-card>
</template>

<script>
import { Bar } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "mainChart",
  components: {
    Bar,
  },

  props: ["mylabels", "mydata"],

  data() {
    return {
      reactive: true,
      chartData: {
        labels: this.mylabels,
        datasets: [
          {
            data: this.mydata, // Specify the data values array
            label: "Regs DATA",
            //barPercentage: 1.0,
            barThickness: 40,
            //maxBarThickness: 8,
            //minBarLength: 10,
            backgroundColor: "rgb(254, 176, 25,0.4)", // Add custom color background (Points and Fill)
            borderColor: "rgb(254, 176, 25,0.9)",
            borderWidth: 1, // Specify bar border width
          },
        ],
      },
      chartOptions: {
        //indexAxis: "y", // to make horizental bar
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
