import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items : [],
    apiUrl: 'https://fair.smartuni.app/api', 
    // 'http://192.168.43.158:4210/api',
    // https://fair.smartuni.app/api    // telephone No : 77062200 PW: 112233
    token : null,
    userCredentials: null,
    companyStatus : [
      { idCompanyStatus: 1, companyStatusName: "PENDING" },
      { idCompanyStatus: 2, companyStatusName: "APPROVED" },
      { idCompanyStatus: 3, companyStatusName: "REJECTED" },
      { idCompanyStatus: 4, companyStatusName: "FREEZED" },
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
     
  },
  modules: {
  }
})
