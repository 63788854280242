<template>
  <div>
    <v-overlay :value="overlay" color="primary" opacity="0.1">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-container style="margin-top: 50px" v-if="!overlay">
      <v-row>
        <v-col
          lg="3"
          md="6"
          sm="12"
          v-for="(n, index) in icon"
          :key="index"
          cols="3"
        >
          <DashboardCard :dash="n" :val="statistics.val[index]" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <mainChart
            :mylabels="statistics.userRegisteredByMonths"
            :mydata="statistics.userCount"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardCard from "../components/DashboardCard";
import mainChart from "../components/mainChart";

export default {
  name: "Home",
  components: {
    DashboardCard,
    mainChart,
  },

  data: () => ({
    icon: [
      {
        myicon: "mdi-account",
        color: "#134776",
        subtitle: "Total Registered Users",
      },
      {
        myicon: "mdi-tag-multiple-outline",
        color: "#ff6c60",
        subtitle: "Total Active Users",
      },
      {
        myicon: "mdi-account-plus",
        color: "#f8d347",
        subtitle: "Total Members",
      },
      {
        myicon: "mdi-account-group",
        color: "#57c8f2",
        subtitle: "Total Active Members",
      },
      { myicon: "mdi-post", color: "#28a745", subtitle: "Total Posts" },
      {
        myicon: "mdi-chart-bell-curve-cumulative",
        color: "#007bff",
        subtitle: "Total Products",
      },
    ],

    statistics: [],
    title: "Home title",
    overlay: true,
  }),
  created() {
    this.getDATA();
  },
  methods: {
    getDATA() {
      this.$http
        .get(`${this.$store.state.apiUrl}/homeStatistics`, {
          headers: { Authorization: `Berar ${this.$store.state.token}` },
        })
        .then((res) => {
          console.log(res.data);
          this.statistics = res.data;

          var aar_month_size = 0;
          aar_month_size = this.statistics.userRegisteredByMonths.length;

          if (aar_month_size < 12) {
            for (let i = aar_month_size + 1; i <= 12; i++) {
              this.statistics.userRegisteredByMonths.push("");
              this.statistics.userCount.push(0);
              //console.log("Posts : ", this.statistics.userRegisteredByMonths);
              //console.log("number of likes : ", this.statistics.userCount);
            }
          }
          // console.log(this.statistics.userRegisteredByMonths.length); // sar for checking
          this.overlay = false;
        })
        .catch((e) => {
          this.overlay = false;
        });
    },
  },
};
</script>
