import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Customers',
    name: 'Customers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Customers.vue')  // NOTE : this is havey work
  },
  {
    path: '/Allmembers',
    name: 'Allmembers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Allmembers.vue')  // NOTE : this is havey work
  },
 
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')   
  },
  {
    path: '/Banners',
    name: 'Banners',
    component: () => import('../views/Banners.vue')   
  },
  {
    path: '/Posts',
    name: 'Posts',
    component: () => import('../views/AllPosts.vue')   
  },

  {
    path: '/Products',
    name: 'Products',
    component: () => import('../views/AllProducts.vue')   
  },


  {
    path: '/reviewsPosts',
    name: 'reviewsPosts',
    component: () => import('../views/reviewsPosts.vue')   
  },
  {
    path: '/reviewsProducts',
    name: 'reviewsProducts',
    component: () => import('../views/reviewsProducts.vue')   
  },
  {
    path: '/AllPushNotification',
    name: 'AllPushNotification',
    component: () => import('../views/AllPushNotification.vue')   
  },
  {
    path: '/UrgentNotification',
    name: 'UrgentNotification',
    component: () => import('../views/UrgentNotification.vue')   
  },
  {
    path: '/Categories',
    name: 'Categories',
    component: () => import('../views/Categories.vue')   
  },
  {
    path: '/Currencies',
    name: 'Currencies',
    component: () => import('../views/Currencies.vue')   
  },
  {
    path: '/Cities',
    name: 'Cities',
    component: () => import('../views/Cities.vue')   
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/News.vue')   
  },
  {
    path: '/Chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue')   
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
